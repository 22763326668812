<template>
  <div>
    <b-modal
      id="booststrapmodal"
      :title="T('Web.Generic.ChoosePictogram')"
      :visible="visible"
      @hidden="handleModalHidden"
      size="lg"
      scrollable
      hide-footer
    >
      <div class="search-bar">
        <b-form-input v-model="searchTerm" :placeholder="T('Web.Generic.Search')" />
      </div>
      <div class="picktogram-list">
        <div
          class="picktogram-item"
          v-for="picktogram in filteredPicktograms"
          :key="picktogram.name"
          @click="handlePicktogramClicked(picktogram.path)"
        >
          <img
            :src="`${apiBaseUrl()}/images/system/fractions/pictograms/${
              picktogram.path
            }`"
          />
        </div>
      </div>
      <template #modal-footer> </template>
    </b-modal>
  </div>
</template>

<script>
import { BFormInput } from "bootstrap-vue";
export default {
  components: { BFormInput },
  data() {
    return {
      visible: false,
      searchTerm: "",
      resolvePromise: undefined,
      rejectPromise: undefined,
      picktograms: [
        {
          name: "Ingen",
          path: "fraction-no-pictogram.jpg",
        },

        {
          name: "ASBEST_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "ASBEST_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "ASBEST_cmyk_300dpi.jpg_300x400..jpg",
          path: "ASBEST_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "ASFALT_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "ASFALT_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "ASFALT_cmyk_300DPI.jpg_300x400..jpg",
          path: "ASFALT_cmyk_300DPI.jpg_300x400..jpg",
        },
        {
          name: "ASKE_cmyk_300dpi.jpg_300x400..jpg",
          path: "ASKE_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "BATTERIER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "BATTERIER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "BATTERIER_cmyk_300dpi.jpg_300x400..jpg",
          path: "BATTERIER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "BETON_300dpi_cmyk.jpg_300x400..jpg",
          path: "BETON_300dpi_cmyk.jpg_300x400..jpg",
        },
        {
          name: "BETON_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "BETON_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "BILBATTERIER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "BILBATTERIER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "BILBATTERIER_cmyk_300dpi.jpg_300x400..jpg",
          path: "BILBATTERIER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "BIOAFFALD_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "BIOAFFALD_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "BIOAFFALD_cmyk_300dpi.jpg_300x400..jpg",
          path: "BIOAFFALD_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "BLOED_PLAST_2_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "BLOED_PLAST_2_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "BLOED_PLAST_2_cmyk_300dpi.jpg_300x400..jpg",
          path: "BLOED_PLAST_2_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "BLOED_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "BLOED_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "BLOED_PLAST_cmyk_300dpi.jpg_300x400..jpg",
          path: "BLOED_PLAST_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "BLOED_PVC_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "BLOED_PVC_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "BLOED_PVC_cmyk_300dpi.jpg_300x400..jpg",
          path: "BLOED_PVC_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "BOEGER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "BOEGER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "BOEGER_cmyk_300dpi.jpg_300x400..jpg",
          path: "BOEGER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "BRANDSLUKKERE_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "BRANDSLUKKERE_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "BRANDSLUKKERE_cmyk_300dpi.jpg_300x400..jpg",
          path: "BRANDSLUKKERE_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "CD_&_DVD_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "CD_&_DVD_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "CD_&_DVD_cmyk_300dpi.jpg_300x400..jpg",
          path: "CD_&_DVD_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "CYKLER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "CYKLER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "CYKLER_cmyk_300dpi.jpg_300x400..jpg",
          path: "CYKLER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "DAEK_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "DAEK_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "DAEK_cmyk_300dpi.jpg_300x400..jpg",
          path: "DAEK_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "DEPONI_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "DEPONI_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "DRIKKEDAASER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "DRIKKEDAASER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "DRIKKEDAASER_cmyk_300dpi.jpg_300x400..jpg",
          path: "DRIKKEDAASER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "ELEKTRONIK_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "ELEKTRONIK_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "ELEKTRONIK_cmyk_300dpi.jpg_300x400..jpg",
          path: "ELEKTRONIK_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "ELPAERER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "ELPAERER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "ELPAERER_cmyk_300dpi.jpg_300x400..jpg",
          path: "ELPAERER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "FARLIGT_AFFALD_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "FARLIGT_AFFALD_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "FARLIGT_AFFALD_cmyk_300dpi.jpg_300x400..jpg",
          path: "FARLIGT_AFFALD_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "FARVET_BLOED_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "FARVET_BLOED_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "FARVET_BLOED_PLAST_cmyk_300dpi.jpg_300x400..jpg",
          path: "FARVET_BLOED_PLAST_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "FLADT_GLAS_cmyk_300dpi.jpg_300x400..jpg",
          path: "FLADT_GLAS_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "FLADT_GLAS_cmyk_sort_300dpi.jpg_300x400..jpg",
          path: "FLADT_GLAS_cmyk_sort_300dpi.jpg_300x400..jpg",
        },
        {
          name: "FLAMINGO_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "FLAMINGO_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "FLAMINGO_cmyk_300dpi.jpg_300x400..jpg",
          path: "FLAMINGO_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "FYRVAERKERI_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "FYRVAERKERI_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "FYRVAERKERI_cmyk_300dpi.jpg_300x400..jpg",
          path: "FYRVAERKERI_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "Gebyr.jpg_300x400..jpg",
          path: "Gebyr.jpg_300x400..jpg",
        },
        {
          name: "GENBRUG_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "GENBRUG_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "GENBRUG_cmyk_300dpi.jpg_300x400..jpg",
          path: "GENBRUG_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "GIPS_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "GIPS_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "GIPS_cmyk_300dpi.jpg_300x400..jpg",
          path: "GIPS_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "GLAS_cmyk_300dpi.jpg_300x400..jpg",
          path: "GLAS_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "GLAS_cmyk_sort_300dpi.jpg_300x400..jpg",
          path: "GLAS_cmyk_sort_300dpi.jpg_300x400..jpg",
        },
        {
          name: "GLASULD_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "GLASULD_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "GLASULD_cmyk_300dpi.jpg_300x400..jpg",
          path: "GLASULD_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "HAARD_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "HAARD_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "HAARD_PLAST_cmyk_300dpi.jpg_300x400..jpg",
          path: "HAARD_PLAST_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "HAARD_PVC_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "HAARD_PVC_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "HAARD_PVC_cmyk_300dpi.jpg_300x400..jpg",
          path: "HAARD_PVC_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "HAVEAFFALD_cmyk_300_dpi.jpg_300x400..jpg",
          path: "HAVEAFFALD_cmyk_300_dpi.jpg_300x400..jpg",
        },
        {
          name: "HAVEAFFALD_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "HAVEAFFALD_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "HELE_MURSTEN_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "HELE_MURSTEN_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "HELE_MURSTEN_cmyk_300dpi.jpg_300x400..jpg",
          path: "HELE_MURSTEN_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "INDENDOERS_TRAE_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "INDENDOERS_TRAE_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "INDENDOERS_TRAE_cmyk_300dpi.jpg_300x400..jpg",
          path: "INDENDOERS_TRAE_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "INDENDOERS_UMALET_TRAE_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "INDENDOERS_UMALET_TRAE_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "INDENDOERS_UMALET_TRAE_cmyk_300dpi.jpg_300x400..jpg",
          path: "INDENDOERS_UMALET_TRAE_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "INDENDOERS_MALET_TRAE_cmyk_300dpi.jpg_300x400..jpg",
          path: "INDENDOERS_MALET_TRAE_cmyk_300dpi.jpg_300x400..jpg"
        },
        {
          name: "INDENDOERS_MALET_TRAE_cmyk_300dpi.sort.jpg_300x400..jpg",
          path: "INDENDOERS_MALET_TRAE_cmyk_300dpi.sort.jpg_300x400..jpg"
        },
        {
          name: "JORD_cmyk_300_dpi.jpg_300x400..jpg",
          path: "JORD_cmyk_300_dpi.jpg_300x400..jpg",
        },
        {
          name: "JORD_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "JORD_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "KAFFEGRUMS_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "KAFFEGRUMS_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "KAFFEGRUMS_cmyk_300dpi.jpg_300x400..jpg",
          path: "KAFFEGRUMS_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "KLAR_BLOED_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "KLAR_BLOED_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "KLAR_BLOED_PLAST_cmyk_300dpi.jpg_300x400..jpg",
          path: "KLAR_BLOED_PLAST_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "KOELEUDSTYR_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "KOELEUDSTYR_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "KOELEUDSTYR_cmyk_300dpi.jpg_300x400..jpg",
          path: "KOELEUDSTYR_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "KOMPOST_cmyk_300_dpi.jpg_300x400..jpg",
          path: "KOMPOST_cmyk_300_dpi.jpg_300x400..jpg",
        },
        {
          name: "KOMPOST_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "KOMPOST_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "LEDNINGER_&_KABLER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "LEDNINGER_&_KABLER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "LEDNINGER_&_KABLER_cmyk_300dpi.jpg_300x400..jpg",
          path: "LEDNINGER_&_KABLER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "LYSSTOFROER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "LYSSTOFROER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "LYSSTOFROER_cmyk_300dpi.jpg_300x400..jpg",
          path: "LYSSTOFROER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "MAD-_&_DRIKKEKARTONER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "MAD-_&_DRIKKEKARTONER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "MAD-_&_DRIKKEKARTONER_cmyk_300dpi.jpg_300x400..jpg",
          path: "MAD-_&_DRIKKEKARTONER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "MADAFFALD_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "MADAFFALD_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "MADAFFALD_cmyk_300dpi.jpg_300x400..jpg",
          path: "MADAFFALD_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "MAELKE_&_BROEDKASSER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "MAELKE_&_BROEDKASSER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "MAELKE_&_BROEDKASSER_cmyk_300dpi.jpg_300x400..jpg",
          path: "MAELKE_&_BROEDKASSER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "MALING_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "MALING_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "MALING_cmyk_300dpi.jpg_300x400..jpg",
          path: "MALING_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "MEDICIN_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "MEDICIN_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "MEDICIN_cmyk_300dpi.jpg_300x400..jpg",
          path: "MEDICIN_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "MELLEMSTORT_ELEKTRONIK_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "MELLEMSTORT_ELEKTRONIK_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "MELLEMSTORT_ELEKTRONIK_cmyk_300dpi.jpg_300x400..jpg",
          path: "MELLEMSTORT_ELEKTRONIK_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "METAL_2_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "METAL_2_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "METAL_2_cmyk_300dpi_sort.jpg",
          path: "METAL_2_cmyk_300dpi_sort.jpg",
        },
        {
          name: "METAL_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "METAL_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "METAL_cmyk_300dpi.jpg_300x400..jpg",
          path: "METAL_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "MINERALULD_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "MINERALULD_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "MINERALULD_cmyk_300dpi.jpg_300x400..jpg",
          path: "MINERALULD_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "MURSTEN_&_TEGL_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "MURSTEN_&_TEGL_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "MURSTEN_&_TEGL_cmyk_300dpi.jpg_300x400..jpg",
          path: "MURSTEN_&_TEGL_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "OLIEFILTRE_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "OLIEFILTRE_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "OLIEFILTRE_cmyk_300dpi.jpg_300x400..jpg",
          path: "OLIEFILTRE_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "OLIEHOLDIGE_MASKINER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "OLIEHOLDIGE_MASKINER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "OLIEHOLDIGE_MASKINER_cmyk_300dpi.jpg_300x400..jpg",
          path: "OLIEHOLDIGE_MASKINER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "PALLER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "PALLER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "PALLER_cmyk_300dpi.jpg_300x400..jpg",
          path: "PALLER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "PAP_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "PAP_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "PAP_cmyk_300dpi.jpg_300x400..jpg",
          path: "PAP_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "PAPIR_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "PAPIR_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "PAPIR_cmyk_300dpi.jpg_300x400..jpg",
          path: "PAPIR_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "PAPIR_TIL_MAKULERING_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "PAPIR_TIL_MAKULERING_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "PAPIR_TIL_MAKULERING_cmyk_300dpi.jpg_300x400..jpg",
          path: "PAPIR_TIL_MAKULERING_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "PLAST_cmyk_300dpi.jpg_300x400..jpg",
          path: "PLAST_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "PLASTHAVEMOEBLER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "PLASTHAVEMOEBLER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "PLASTHAVEMOEBLER_cmyk_300dpi.jpg_300x400..jpg",
          path: "PLASTHAVEMOEBLER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "Plastsække.jfif_300x400..jfif",
          path: "Plastsække.jfif_300x400..jfif",
        },
        {
          name: "POLSTREDE_MOEBLER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "POLSTREDE_MOEBLER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "POLSTREDE_MOEBLER_cmyk_300dpi.jpg_300x400..jpg",
          path: "POLSTREDE_MOEBLER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "PORCELAEN_cmyk_300dpi.jpg_300x400..jpg",
          path: "PORCELAEN_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "PORCELAEN_cmyk_sort_300dpi.jpg_300x400..jpg",
          path: "PORCELAEN_cmyk_sort_300dpi.jpg_300x400..jpg",
        },
        {
          name: "PRINTERPATRONER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "PRINTERPATRONER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "PRINTERPATRONER_cmyk_300dpi.jpg_300x400..jpg",
          path: "PRINTERPATRONER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "REPARATION_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "REPARATION_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "REPARATION_cmyk_300dpi.jpg_300x400..jpg",
          path: "REPARATION_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "REST_EFTER_SORTERING_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "REST_EFTER_SORTERING_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "RESTAFFALD_cmyk_300dpi.jpg_300x400..jpg",
          path: "RESTAFFALD_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "ROEGALARMER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "ROEGALARMER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "ROEGALARMER_cmyk_300dpi.jpg_300x400..jpg",
          path: "ROEGALARMER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "SANITET_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "SANITET_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "SANITET_cmyk_300dpi.jpg_300x400..jpg",
          path: "SANITET_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "SKO_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "SKO_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "SKO_cmyk_300dpi.jpg_300x400..jpg",
          path: "SKO_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "SMAAT_BRAENDBART_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "SMAAT_BRAENDBART_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "SMAAT_ELEKTRONIK_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "SMAAT_ELEKTRONIK_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "SMAAT_ELEKTRONIK_cmyk_300dpi.jpg_300x400..jpg",
          path: "SMAAT_ELEKTRONIK_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "SOLPANELER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "SOLPANELER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "SOLPANELER_cmyk_300dpi.jpg_300x400..jpg",
          path: "SOLPANELER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "SPILDOLIE_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "SPILDOLIE_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "SPILDOLIE_cmyk_300dpi.jpg_300x400..jpg",
          path: "SPILDOLIE_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "SPRAYDAASER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "SPRAYDAASER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "SPRAYDAASER_cmyk_300dpi.jpg_300x400..jpg",
          path: "SPRAYDAASER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "STEN_&_GRUS_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "STEN_&_GRUS_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "STEN_&_GRUS_cmyk_300dpi.jpg_300x400..jpg",
          path: "STEN_&_GRUS_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "STENULD_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "STENULD_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "STENULD_cmyk_300dpi.jpg_300x400..jpg",
          path: "STENULD_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "STOR_BLOED_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "STOR_BLOED_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "STOR_BLOED_PLAST_cmyk_300dpi.jpg_300x400..jpg",
          path: "STOR_BLOED_PLAST_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "STOR_HAARD_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "STOR_HAARD_PLAST_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "STOR_HAARD_PLAST_cmyk_300dpi.jpg_300x400..jpg",
          path: "STOR_HAARD_PLAST_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "STORT_ELEKTRONIK_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "STORT_ELEKTRONIK_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "STORT_ELEKTRONIK_cmyk_300dpi.jpg_300x400..jpg",
          path: "STORT_ELEKTRONIK_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "TAGPAP_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "TAGPAP_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "TAGPAP_cmyk_300dpi.jpg_300x400..jpg",
          path: "TAGPAP_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "TEKSTILAFFALD CMYK 300dpi.jpg_300x400..jpg",
          path: "TEKSTILAFFALD CMYK 300dpi.jpg_300x400..jpg",
        },
        {
          name: "TEKSTILAFFALD CMYK sort 300dpi.jpg_300x400..jpg",
          path: "TEKSTILAFFALD CMYK sort 300dpi.jpg_300x400..jpg",
        },
        {
          name: "TIL_NEDGRAVNING_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "TIL_NEDGRAVNING_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "TOEJ_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "TOEJ_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "TOEJ_cmyk_300dpi.jpg_300x400..jpg",
          path: "TOEJ_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "TRAESTAMMER_&_ROEDDER_cmyk_300_dpi.jpg_300x400..jpg",
          path: "TRAESTAMMER_&_ROEDDER_cmyk_300_dpi.jpg_300x400..jpg",
        },
        {
          name: "TRAESTAMMER_&_ROEDDER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "TRAESTAMMER_&_ROEDDER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "TRYKFLASKER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "TRYKFLASKER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "TRYKFLASKER_cmyk_300dpi.jpg_300x400..jpg",
          path: "TRYKFLASKER_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "TV_&_SKAERME_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "TV_&_SKAERME_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "TV_&_SKAERME_cmyk_300dpi.jpg_300x400..jpg",
          path: "TV_&_SKAERME_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "UDENDOERS_TRAE_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "UDENDOERS_TRAE_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "UDENDOERS_TRAE_cmyk_300dpi.jpg_300x400..jpg",
          path: "UDENDOERS_TRAE_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "VHS-_&_KASSETTEBAAND_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "VHS-_&_KASSETTEBAAND_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "VHS-_&_KASSETTEBAAND_cmyk_300dpi.jpg_300x400..jpg",
          path: "VHS-_&_KASSETTEBAAND_cmyk_300dpi.jpg_300x400..jpg",
        },
        {
          name: "VINDUER_cmyk_300dpi_sort.jpg_300x400..jpg",
          path: "VINDUER_cmyk_300dpi_sort.jpg_300x400..jpg",
        },
        {
          name: "VINDUER_cmyk_300dpi.jpg_300x400..jpg",
          path: "VINDUER_cmyk_300dpi.jpg_300x400..jpg",
        },

        /** 
        {
          name: "BATTERIER_cmyk_300dpi.jpg",
          path: "BATTERIER_cmyk_300dpi.jpg",
        },
        {
          name: "BATTERIER_cmyk_300dpi_sort.jpg",
          path: "BATTERIER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "BILBATTERIER_cmyk_300dpi.jpg",
          path: "BILBATTERIER_cmyk_300dpi.jpg",
        },
        {
          name: "BILBATTERIER_cmyk_300dpi_sort.jpg",
          path: "BILBATTERIER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "BIOAFFALD_cmyk_300dpi.jpg",
          path: "BIOAFFALD_cmyk_300dpi.jpg",
        },
        {
          name: "BIOAFFALD_cmyk_300dpi_sort.jpg",
          path: "BIOAFFALD_cmyk_300dpi_sort.jpg",
        },
        {
          name: "BLOED_PLAST_cmyk_300dpi.jpg",
          path: "BLOED_PLAST_cmyk_300dpi.jpg",
        },
        {
          name: "BLOED_PLAST_cmyk_300dpi_sort.jpg",
          path: "BLOED_PLAST_cmyk_300dpi_sort.jpg",
        },
        {
          name: "BLOED_PVC_cmyk_300dpi.jpg",
          path: "BLOED_PVC_cmyk_300dpi.jpg",
        },
        {
          name: "BLOED_PVC_cmyk_300dpi_sort.jpg",
          path: "BLOED_PVC_cmyk_300dpi_sort.jpg",
        },
        {
          name: "BOEGER_cmyk_300dpi.jpg",
          path: "BOEGER_cmyk_300dpi.jpg",
        },
        {
          name: "BOEGER_cmyk_300dpi_sort.jpg",
          path: "BOEGER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "DEPONI_cmyk_300dpi_sort.jpg",
          path: "DEPONI_cmyk_300dpi_sort.jpg",
        },
        {
          name: "DRIKKEDAASER_cmyk_300dpi.jpg",
          path: "DRIKKEDAASER_cmyk_300dpi.jpg",
        },
        {
          name: "DRIKKEDAASER_cmyk_300dpi_sort.jpg",
          path: "DRIKKEDAASER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "ELPAERER_cmyk_300dpi.jpg",
          path: "ELPAERER_cmyk_300dpi.jpg",
        },
        {
          name: "ELPAERER_cmyk_300dpi_sort.jpg",
          path: "ELPAERER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "FARLIGT_AFFALD_cmyk_300dpi.jpg",
          path: "FARLIGT_AFFALD_cmyk_300dpi.jpg",
        },
        {
          name: "FARLIGT_AFFALD_cmyk_300dpi_sort.jpg",
          path: "FARLIGT_AFFALD_cmyk_300dpi_sort.jpg",
        },
        {
          name: "FARVET_BLOED_PLAST_cmyk_300dpi.jpg",
          path: "FARVET_BLOED_PLAST_cmyk_300dpi.jpg",
        },
        {
          name: "FARVET_BLOED_PLAST_cmyk_300dpi_sort.jpg",
          path: "FARVET_BLOED_PLAST_cmyk_300dpi_sort.jpg",
        },
        {
          name: "FLADT_GLAS_cmyk_300dpi.jpg",
          path: "FLADT_GLAS_cmyk_300dpi.jpg",
        },
        {
          name: "FLADT_GLAS_cmyk_sort_300dpi.jpg",
          path: "FLADT_GLAS_cmyk_sort_300dpi.jpg",
        },
        {
          name: "FLAMINGO_cmyk_300dpi.jpg",
          path: "FLAMINGO_cmyk_300dpi.jpg",
        },
        {
          name: "FLAMINGO_cmyk_300dpi_sort.jpg",
          path: "FLAMINGO_cmyk_300dpi_sort.jpg",
        },
        {
          name: "GENBRUG_cmyk_300dpi.jpg",
          path: "GENBRUG_cmyk_300dpi.jpg",
        },
        {
          name: "GENBRUG_cmyk_300dpi_sort.jpg",
          path: "GENBRUG_cmyk_300dpi_sort.jpg",
        },
        {
          name: "GLAS_cmyk_300dpi.jpg",
          path: "GLAS_cmyk_300dpi.jpg",
        },
        {
          name: "GLAS_cmyk_sort_300dpi.jpg",
          path: "GLAS_cmyk_sort_300dpi.jpg",
        },
        {
          name: "HAARD_PLAST_cmyk_300dpi.jpg",
          path: "HAARD_PLAST_cmyk_300dpi.jpg",
        },
        {
          name: "HAARD_PLAST_cmyk_300dpi_sort.jpg",
          path: "HAARD_PLAST_cmyk_300dpi_sort.jpg",
        },
        {
          name: "HAARD_PVC_cmyk_300dpi.jpg",
          path: "HAARD_PVC_cmyk_300dpi.jpg",
        },
        {
          name: "HAARD_PVC_cmyk_300dpi_sort.jpg",
          path: "HAARD_PVC_cmyk_300dpi_sort.jpg",
        },
        {
          name: "HAVEAFFALD_cmyk_300dpi_sort.jpg",
          path: "HAVEAFFALD_cmyk_300dpi_sort.jpg",
        },
        {
          name: "HAVEAFFALD_cmyk_300_dpi.jpg",
          path: "HAVEAFFALD_cmyk_300_dpi.jpg",
        },
        {
          name: "JORD_cmyk_300dpi_sort.jpg",
          path: "JORD_cmyk_300dpi_sort.jpg",
        },
        {
          name: "JORD_cmyk_300_dpi.jpg",
          path: "JORD_cmyk_300_dpi.jpg",
        },
        {
          name: "KAFFEGRUMS_cmyk_300dpi.jpg",
          path: "KAFFEGRUMS_cmyk_300dpi.jpg",
        },
        {
          name: "KAFFEGRUMS_cmyk_300dpi_sort.jpg",
          path: "KAFFEGRUMS_cmyk_300dpi_sort.jpg",
        },
        {
          name: "KLAR_BLOED_PLAST_cmyk_300dpi.jpg",
          path: "KLAR_BLOED_PLAST_cmyk_300dpi.jpg",
        },
        {
          name: "KLAR_BLOED_PLAST_cmyk_300dpi_sort.jpg",
          path: "KLAR_BLOED_PLAST_cmyk_300dpi_sort.jpg",
        },
        {
          name: "KOELEUDSTYR_cmyk_300dpi.jpg",
          path: "KOELEUDSTYR_cmyk_300dpi.jpg",
        },
        {
          name: "KOELEUDSTYR_cmyk_300dpi_sort.jpg",
          path: "KOELEUDSTYR_cmyk_300dpi_sort.jpg",
        },
        {
          name: "LEDNINGER_&_KABLER_cmyk_300dpi.jpg",
          path: "LEDNINGER_&_KABLER_cmyk_300dpi.jpg",
        },
        {
          name: "LEDNINGER_&_KABLER_cmyk_300dpi_sort.jpg",
          path: "LEDNINGER_&_KABLER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "LYSSTOFROER_cmyk_300dpi.jpg",
          path: "LYSSTOFROER_cmyk_300dpi.jpg",
        },
        {
          name: "LYSSTOFROER_cmyk_300dpi_sort.jpg",
          path: "LYSSTOFROER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "MAD-_&_DRIKKEKARTONER_cmyk_300dpi.jpg",
          path: "MAD-_&_DRIKKEKARTONER_cmyk_300dpi.jpg",
        },
        {
          name: "MAD-_&_DRIKKEKARTONER_cmyk_300dpi_sort.jpg",
          path: "MAD-_&_DRIKKEKARTONER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "MADAFFALD_cmyk_300dpi.jpg",
          path: "MADAFFALD_cmyk_300dpi.jpg",
        },
        {
          name: "MADAFFALD_cmyk_300dpi_sort.jpg",
          path: "MADAFFALD_cmyk_300dpi_sort.jpg",
        },
        {
          name: "MALING_cmyk_300dpi.jpg",
          path: "MALING_cmyk_300dpi.jpg",
        },
        {
          name: "MALING_cmyk_300dpi_sort.jpg",
          path: "MALING_cmyk_300dpi_sort.jpg",
        },
        {
          name: "METAL_2_cmyk_300dpi.jpg",
          path: "METAL_2_cmyk_300dpi.jpg",
        },
        {
          name: "METAL_2_cmyk_300dpi_sort.jpg",
          path: "METAL_2_cmyk_300dpi_sort.jpg",
        },
        {
          name: "METAL_cmyk_300dpi.jpg",
          path: "METAL_cmyk_300dpi.jpg",
        },
        {
          name: "METAL_cmyk_300dpi_sort.jpg",
          path: "METAL_cmyk_300dpi_sort.jpg",
        },
        {
          name: "OLIEHOLDIGE_MASKINER_cmyk_300dpi.jpg",
          path: "OLIEHOLDIGE_MASKINER_cmyk_300dpi.jpg",
        },
        {
          name: "OLIEHOLDIGE_MASKINER_cmyk_300dpi_sort.jpg",
          path: "OLIEHOLDIGE_MASKINER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "PALLER_cmyk_300dpi.jpg",
          path: "PALLER_cmyk_300dpi.jpg",
        },
        {
          name: "PALLER_cmyk_300dpi_sort.jpg",
          path: "PALLER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "PAPIR_cmyk_300dpi.jpg",
          path: "PAPIR_cmyk_300dpi.jpg",
        },
        {
          name: "PAPIR_cmyk_300dpi_sort.jpg",
          path: "PAPIR_cmyk_300dpi_sort.jpg",
        },
        {
          name: "PAPIR_TIL_MAKULERING_cmyk_300dpi.jpg",
          path: "PAPIR_TIL_MAKULERING_cmyk_300dpi.jpg",
        },
        {
          name: "PAPIR_TIL_MAKULERING_cmyk_300dpi_sort.jpg",
          path: "PAPIR_TIL_MAKULERING_cmyk_300dpi_sort.jpg",
        },
        {
          name: "PAP_cmyk_300dpi.jpg",
          path: "PAP_cmyk_300dpi.jpg",
        },
        {
          name: "PAP_cmyk_300dpi_sort.jpg",
          path: "PAP_cmyk_300dpi_sort.jpg",
        },
        {
          name: "PLAST_cmyk_300dpi.jpg",
          path: "PLAST_cmyk_300dpi.jpg",
        },
        {
          name: "PLAST_cmyk_300dpi_sort.jpg",
          path: "PLAST_cmyk_300dpi_sort.jpg",
        },
        {
          name: "PORCELAEN_cmyk_300dpi.jpg",
          path: "PORCELAEN_cmyk_300dpi.jpg",
        },
        {
          name: "PORCELAEN_cmyk_sort_300dpi.jpg",
          path: "PORCELAEN_cmyk_sort_300dpi.jpg",
        },
        {
          name: "PRINTERPATRONER_cmyk_300dpi.jpg",
          path: "PRINTERPATRONER_cmyk_300dpi.jpg",
        },
        {
          name: "PRINTERPATRONER_cmyk_300dpi_sort.jpg",
          path: "PRINTERPATRONER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "RESTAFFALD_cmyk_300dpi.jpg",
          path: "RESTAFFALD_cmyk_300dpi.jpg",
        },
        {
          name: "REST_EFTER_SORTERING_cmyk_300dpi_sort.jpg",
          path: "REST_EFTER_SORTERING_cmyk_300dpi_sort.jpg",
        },
        {
          name: "SMAAT_BRAENDBART_cmyk_300dpi_sort.jpg",
          path: "SMAAT_BRAENDBART_cmyk_300dpi_sort.jpg",
        },
        {
          name: "SMAAT_ELEKTRONIK_cmyk_300dpi.jpg",
          path: "SMAAT_ELEKTRONIK_cmyk_300dpi.jpg",
        },
        {
          name: "SMAAT_ELEKTRONIK_cmyk_300dpi_sort.jpg",
          path: "SMAAT_ELEKTRONIK_cmyk_300dpi_sort.jpg",
        },
        {
          name: "SPILDOLIE_cmyk_300dpi.jpg",
          path: "SPILDOLIE_cmyk_300dpi.jpg",
        },
        {
          name: "SPILDOLIE_cmyk_300dpi_sort.jpg",
          path: "SPILDOLIE_cmyk_300dpi_sort.jpg",
        },
        {
          name: "SPRAYDAASER_cmyk_300dpi.jpg",
          path: "SPRAYDAASER_cmyk_300dpi.jpg",
        },
        {
          name: "SPRAYDAASER_cmyk_300dpi_sort.jpg",
          path: "SPRAYDAASER_cmyk_300dpi_sort.jpg",
        },
        {
          name: "TEKSTILAFFALD CMYK 300dpi.jpg",
          path: "TEKSTILAFFALD CMYK 300dpi.jpg",
        },
        {
          name: "TEKSTILAFFALD CMYK sort 300dpi.jpg",
          path: "TEKSTILAFFALD CMYK sort 300dpi.jpg",
        },
        {
          name: "TRYKFLASKER_cmyk_300dpi.jpg",
          path: "TRYKFLASKER_cmyk_300dpi.jpg",
        },
        {
          name: "TRYKFLASKER_cmyk_300dpi_sort.jpg",
          path: "TRYKFLASKER_cmyk_300dpi_sort.jpg",
        },
        */
      ],
    };
  },
  methods: {
    open() {
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.visible = false;
    },
    handlePicktogramClicked(path) {
      this.$emit("picktogram-choosen", `system/fractions/pictograms/${path}`);
      this.resolvePromise(`system/fractions/pictograms/${path}`);
      this.handleModalHidden();
    },
    handleCancel() {
      this.resolvePromise(null);
    },
  },
  computed: {
    filteredPicktograms() {
      if (this.searchTerm == "") return this.picktograms;
      return this.picktograms.filter((p) =>
        p.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.picktogram-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .picktogram-item {
    width: 166px;
    margin: 10px;

    img {
      width: 100%;
      border-radius: 8px;
      border: 3px solid #fff;

      &:hover {
        border: 3px solid #407e3d;
        cursor: pointer;
      }
    }
  }
}
</style>