<template>
  <b-card>
    <confirm-modal ref="confirmModal" />
    <create-collector-modal ref="collectorCreateModal" />
    <div class="d-flex justify-content-center flex-column">
      <b-row class="topbar mb-1">
        <b-col cols="3">
          <input
            type="text"
            class="form-control"
            :placeholder="T('Web.Generic.Search', 'Search')"
            v-model="search"
          />
        </b-col>
        <b-col>
          <b-button @click="openCollectorCreateModal" variant="primary">
            {{ T("Web.Generic.CreateNew", "Create new") }}
          </b-button>
        </b-col>
        <loader class="loader" ref="loadercollector"></loader>
      </b-row>
      <b-row>
        <b-table
        class="background-data-table"
          responsive
          show-empty
          :empty-text="T('Web.Generic.TableEmpty')"
          :fields="tableHeadersFields"
          :items="filterted"
        >
          <template #cell(cvr)="data">
            <input
              @change="updateCollector(data.item)"
              type="text"
              class="form-control"
              disabled
              v-model="data.item.cvr"
            />
          </template>
          <template #cell(name)="data">
            <input
              @change="updateCollector(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.contactName"
            />
          </template>
          <template #cell(email)="data">
            <input
              @change="updateCollector(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.contactEmail"
            />
          </template>
          <template #cell(phone)="data">
            <input
              @change="updateCollector(data.item)"
              type="text"
              class="form-control"
              :placeholder="T('Web.Generic.Phone', 'Phone')"
              v-model="data.item.contactPhone"
            />
          </template>
              <template #cell(customerNumberAtSupplier)="data">
            <input
              @change="updateCollector(data.item)"
              type="text"
              class="form-control"
              :placeholder="T('Web.Generic.CustomerNumber', 'Customer number')"
              v-model="data.item.customerNumberAtSupplier"
            />
          </template>
          <template #cell()="data">
            <b-button
              variant="outline-danger"
              @click="removeCollector(data.item)"
            >
              {{ T("Web.Generic.Archive", "Archive") }}
            </b-button>
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import CreateCollectorModal from "@/components/modals/infoCards/CreateCollectorModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import loader from "@/components/layout/loader.vue";

import { BCard, BButton, BRow, BCol, BTable } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    CreateCollectorModal,
    loader,
    ConfirmModal,
  },
  async created() {
    await this.fetchCollectors();
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    ...mapGetters({ collectors: "collectors/list" }),
    filterted() {
      if (this.search == null || this.search == "") return this.collectors;

      let filtered = this.collectors.filter((e) => {
        if (
          e.cvr != null
            ? e.cvr.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            : false
        )
          return true;

        if (
          e.contactName != null
            ? e.contactName.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1
            : false
        )
          return true;

        if (
          e.contactEmail != null
            ? e.contactEmail.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1
            : false
        )
          return true;
        return false;
      });

      return filtered;
    },
    tableHeadersFields() {
      return [
        {
          key: "cvr",
          label: this.T("Web.Generic.Cvr", "Cvr"),
          sortable: true,
        },
        {
          key: "name",
          label: this.T("Web.Generic.Name", "Name"),
          sortable: true,
        },
        {
          key: "email",
          label: this.T("Web.Generic.Email", "Email"),
          sortable: true,
        },
        {
          key: "phone",
          label: this.T("Web.Generic.Phone", "Phone"),
          sortable: true,
        },
        {
          key: "customerNumberAtSupplier",
          label: this.T("Web.Generic.CustomerNumber", "Customer number"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchCollectors: "collectors/fetchList",
      putCollectors: "collectors/put",
      removeCollectors: "collectors/delete",
    }),

    async openCollectorCreateModal() {
      const result = await this.$refs.collectorCreateModal.open("");
      if (result != undefined && result.id.length > 0)
        await this.fetchCollectors();
    },
    async removeCollector(collector) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.ArchiveConfirm",
            "Confirm that you are about to archive this item"
          )
        ))
      )
        return;

      this.$refs.loadercollector.loading();
      await this.removeCollectors({ resourceId: collector.id });
      await this.fetchCollectors();
      this.$refs.loadercollector.loadCompelete();
    },
    async updateCollector(collector) {
      this.$refs.loadercollector.loading();
      await this.putCollectors({ data: collector });
      this.$refs.loadercollector.loadCompelete();
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
</style>