<template>
  <b-card>
    <confirm-modal ref="confirmModal" />

    <div class="d-flex justify-content-center flex-column">
      <b-row class="topbar mb-1">
        <b-col>
          <b-button variant="primary" @click="createContainer">
            {{ T("Web.Generic.CreateNew", "Create new") }}
          </b-button>
        </b-col>
        <loader class="loader" ref="loader"></loader>
      </b-row>
      <b-row>
        <b-table
        class="background-data-table"
          responsive
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          :fields="tableHeadersFields"
          :items="containers"
        >
          <template #cell(refId)="data">
            <input
              @change="update(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.refId"
              style="min-width: 300px"
            />
          </template>
          <template #cell(description)="data">
            <input
              @change="update(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.description"
              style="min-width: 300px"
            />
          </template>
          <template #cell(action)="data">
            <b-button
              variant="outline-danger"
              @click="deleteContainer(data.item)"
            >
              {{ T("Web.Generic.Archive", "Archive") }}
            </b-button>
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BButton, BRow, BCol, BTable } from "bootstrap-vue";
import loader from "@/components/layout/loader.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    ConfirmModal,
    loader,
  },
  async created() {
    await this.fetchContainers();
  },
  computed: {
    ...mapGetters({ containers: "containers/list" }),
    tableHeadersFields() {
      return [
        {
          key: "refId",
          label: this.T("Web.Generic.Name"),
          sortable: true,
        },
        {
          key: "description",
          label: this.T("Web.Generic.Description", "Description"),
        },
        {
          key: "action",
          label: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchContainers: "containers/fetchList",
      create: "containers/create",
      put: "containers/put",
      delete: "containers/delete",
    }),
    async createContainer() {
      this.$refs.loader.loading();
      await this.create({ data: {} });
      await this.fetch();
      this.$refs.loader.loadCompelete();
    },
    async update(entity) {
      this.$refs.loader.loading();
      await this.put({ data: entity });
      this.$refs.loader.loadCompelete();
    },
    async deleteContainer(container) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.ArchiveConfirm",
            "Confirm that you are about to archive this item"
          )
        ))
      )
        return;

      this.$refs.loader.loading();
      await this.delete({ resourceId: container.id });
      await this.fetchContainers();
      this.$refs.loader.loadCompelete();
    },
  },
};
</script>