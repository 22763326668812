<template>
  <b-card>
    <confirm-modal ref="confirmModal" />

    <div class="d-flex justify-content-center flex-column">
      <b-row class="topbar mb-1">
        <b-col>
          <b-button variant="primary" @click="createTransportvehicle">
            {{ T("Web.Generic.CreateNew", "Create new") }}
          </b-button>
        </b-col>
        <loader class="loader" ref="loader"></loader>
      </b-row>
      <b-row>
        <b-table
          class="background-data-table"
          responsive
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          :fields="tableHeadersFields"
          :items="transportvehicle"
        >
          <template #cell(name)="data">
            <input
              @change="update(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.name"
              style="min-width: 300px"
            />
          </template>
          <template #cell(transportCo2)="data">
            <input
              @change="update(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.transportCo2"
            />
          </template>
          <template #cell(onSpotPickupCo2)="data">
            <input
              @change="update(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.onSpotPickupCo2"
            />
          </template>
          <template #cell(action)="data">
            <b-button
              variant="outline-danger"
              @click="deleteTransportvehicle(data.item)"
            >
              {{ T("Web.Generic.Archive", "Archive") }}
            </b-button>
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import loader from "@/components/layout/loader.vue";
import { BCard, BButton, BRow, BCol, BTable } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    ConfirmModal,
    loader,
  },
  async created() {
    await this.fetch();
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      transportvehicle: "transportvehicle/list",
    }),
    tableHeadersFields() {
      return [
        {
          key: "name",
          label: this.T("Web.Generic.Name"),
          sortable: true,
        },
        {
          key: "transportCo2",
          label: this.T(
            "Web.Generic.TransportVehivles.TransportCo2",
            "Transport Co2"
          ),
        },
        {
          key: "onSpotPickupCo2",
          label: this.T(
            "Web.Generic.TransportVehivles.OnSpotPickupCo2",
            "On Spot Pickup Co2"
          ),
        },
        {
          key: "action",
          label: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetch: "transportvehicle/fetchList",
      put: "transportvehicle/put",
      create: "transportvehicle/create",
      delete: "transportvehicle/delete",
    }),
    async deleteTransportvehicle(transportvehicle) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.ArchiveConfirm",
            "Confirm that you are about to archive this item"
          )
        ))
      )
        return;

      this.$refs.loader.loading();
      await this.delete({ resourceId: transportvehicle.id });
      await this.fetch();
      this.$refs.loader.loadCompelete();
    },
    async createTransportvehicle() {
      this.$refs.loader.loading();
      await this.create({ data: {} });
      await this.fetch();
      this.$refs.loader.loadCompelete();
    },
    async update(entity) {
      this.$refs.loader.loading();
      await this.put({ data: entity });
      this.$refs.loader.loadCompelete();
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
</style>