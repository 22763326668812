<template>
  <div>
    <b-modal
      id="booststrapmodal"
      :title="
        T(
          'Web.Generic.WasteFractions.CreateWasteFraction',
          'Create new fraction'
        )
      "
      centered
      :visible="visible"
      @hidden="handleModalHidden"
    >
      <!-- Body -->
      <b-form @submit.stop.prevent="handleSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group :label="T('Web.Generic.Name')" label-for="name">
              <b-form-input id="name" v-model="model.name" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <!-- End Body -->

      <!-- Footer -->
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="!canSubmit || submitting"
            @click="handleSubmit"
          >
            {{ T("Web.Generic.Create") }}
          </b-button>
          <b-button
            variant="light"
            class="float-right mr-2"
            @click="handleCancel"
          >
            {{ T("Web.Generic.Cancel") }}
          </b-button>
        </div>
      </template>
      <!-- End footer -->
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { uuid } from "vue-uuid";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      visible: false,
      submitting: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      model: {
        name: undefined,
      },
    };
  },
  methods: {
    ...mapActions({ create: "wasteFractions/create" }),
    open(name) {
      this.visible = true;
      this.model.name = name;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.visible = false;
      this.model = {
        name: undefined,
      };
    },
    valueIsNotEmptyOrUnknown(value) {
      return value && value != "Unknown";
    },
    async handleSubmit() {
      try {
        this.submitting = true;
        const id = uuid.v4();
        await this.create({
          data: this.model,
          resourceId: id,
        });
        this.resolvePromise({ id: id });
        this.handleModalHidden();
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
  },
  computed: {
    canSubmit() {
      return this.valueIsNotEmptyOrUnknown(this.model.name);
    },
  },
};
</script>