<template>
  <div>
    <b-tabs
      content-class="col-12 col-md-9 mt-1 mt-md-0 tab-content-container"
      pills
      nav-wrapper-class="background-nav"
    >
      <b-tab @click="active = 'location'">
        <template #title>
          {{ T("Web.BackgroundDataPage.Navigation.Locations", "Locations") }}
        </template>
      </b-tab>
      <b-tab @click="active = 'collectors'">
        <template #title>
          {{ T("Web.BackgroundDataPage.Navigation.Collectors", "Suppliers") }}
        </template>
      </b-tab>
      <b-tab @click="active = 'recivingFacilities'">
        <template #title>
          {{
            T(
              "Web.BackgroundDataPage.Navigation.RecivingFacilities",
              "Reciving facilities"
            )
          }}
        </template>
      </b-tab>
      <b-tab @click="active = 'containerTypes'">
        <template #title>
          {{
            T(
              "Web.BackgroundDataPage.Navigation.ContainerTypes",
              "Container types"
            )
          }}
        </template>
      </b-tab>
      <b-tab @click="active = 'wasteFractions'">
        <template #title>
          {{
            T("Web.BackgroundDataPage.Navigation.WasteFractions", "Waste types")
          }}
        </template>
      </b-tab>
      <b-tab @click="active = 'ewc'">
        <template #title>
          {{ T("Web.BackgroundDataPage.Navigation.EWC", "EWC codes") }}
        </template>
      </b-tab>
      <b-tab @click="active = 'transportvehicle'">
        <template #title>
          {{
            T(
              "Web.BackgroundDataPage.Navigation.TransportVehicle",
              "Transport vehicles"
            )
          }}
        </template>
      </b-tab>
      <b-tab @click="active = 'containers'">
        <template #title>
          {{ T("Web.BackgroundDataPage.Navigation.Containers", "Containers") }}
        </template>
      </b-tab>
      <b-tab @click="active = 'TransportPrices'">
        <template #title>
          {{
            T(
              "Web.BackgroundDataPage.Navigation.TransportPrices",
              "Transport prices"
            )
          }}
        </template>
      </b-tab>
    </b-tabs>

    <waste-fractions v-if="active == 'wasteFractions'"></waste-fractions>
    <container-types v-if="active == 'containerTypes'"></container-types>
    <reciving-facilities
      v-if="active == 'recivingFacilities'"
    ></reciving-facilities>
    <collectors v-if="active == 'collectors'"></collectors>
    <ewc-codes v-if="active == 'ewc'"></ewc-codes>
    <locations v-if="active == 'location'"></locations>
    <transport-vehicle v-if="active == 'transportvehicle'"></transport-vehicle>
    <containers v-if="active == 'containers'"></containers>
    <transport-prices v-if="active == 'TransportPrices'"></transport-prices>
  </div>
</template>
<script>
import WasteFractions from "@/views/sysadmin/WasteFractions.vue";
import ContainerTypes from "@/views/sysadmin/ContainerTypes.vue";
import RecivingFacilities from "@/views/sysadmin/RecivingFacilities.vue";
import Collectors from "@/views/sysadmin/Collectors.vue";
import EwcCodes from "@/views/sysadmin/EwcCodes.vue";
import Locations from "@/views/sysadmin/Locations.vue";
import TransportVehicle from "@/views/sysadmin/TransportVehicle.vue";

import { BTabs, BTab } from "bootstrap-vue";
import Containers from "./Containers.vue";
import TransportPrices from "./TransportPrices.vue";
export default {
  components: {
    BTabs,
    BTab,
    Collectors,
    WasteFractions,
    ContainerTypes,
    RecivingFacilities,
    EwcCodes,
    Locations,
    TransportVehicle,
    Containers,
    TransportPrices,
  },
  async created() {},
  data() {
    return {
      active: "location",
      activeLoading: false,
      type: "border",
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" >
.background-data-table table td {
  padding: 0.72rem 0.2rem !important;
  &:first-child,
  &:last-child {
    padding: 0.72rem 0.5rem !important;
  }
}
.background-data-table table th {
  padding: 0.72rem 0.2rem !important;
  &:first-child,
  &:last-child {
    padding: 0.72rem 1rem !important;
  }
}
</style>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
</style>