<template>
  <b-card>
    <confirm-modal ref="confirmModal" />
    <create-waste-fractions-modal ref="createWasteFractionsModal" />
    <fraction-pictogram-picker-modal ref="fractionPictogramPickerModal" />

    <content-modal
      id="infocards-creation-tool-modal"
      :title="
        T(
          'Web.BackgroundDataPage.WasteTypes.CreateInfocardsOnLocations',
          'Create infocard on location(s)'
        )
      "
      body-class="p-0"
      size="lg"
      :hide-header-close="false"
    >
      <b-container fluid>
        <div
          class="d-flex justify-content-between flex-column"
          style="min-height: 500px"
        >
          <div>
            <b-form-group
              :Label="
                T(
                  'Web.BackgroundDataPage.WasteTypes.CreateInfocardsOnLocationsStep1',
                  '1. choose location(s) to add infocards on'
                )
              "
            >
              <locations-mulit-select
                :locations="locations"
                v-model="selectedLocations"
              />
            </b-form-group>
            <b-form-group
              :Label="
                T(
                  'Web.BackgroundDataPage.WasteTypes.CreateInfocardsOnLocationsStep2',
                  '2. choose fraction(s) to create infocards based on'
                )
              "
            >
              <waste-fraction-multi-select v-model="selectedFractions" />
            </b-form-group>
            <hr />
          </div>
          <div>
            <div
              v-for="infocard in infocardsToCreate"
              :key="infocard.id"
              class="d-flex justify-content-between mb-1"
            >
              <b-form-input
                required
                v-model="infocard.name"
                :disabled="infocard.type == 'ContainerInfoCard'"
                class="mr-1"
              />
              <b-form-select
                :options="containerTypesSelectOptions"
                v-model="infocard.type"
                :disabled="true"
              />
              <b-button
                size="sm"
                variant="flat-danger"
                :disabled="infocard.type == 'ContainerInfoCard'"
                @click="removeInfocardFromCreateList(infocard.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
            <b-button
              size="sm"
              variant="flat-secondary"
              class="w-100"
              @click="addAccountToInfocardCreateList"
            >
              {{
                T(
                  "Web.BackgroundDataPage.WasteTypes.CreateInfocardsOnLocationsStep3",
                  "3. Add accounts to the list (optional)"
                )
              }}
              <feather-icon icon="PlusIcon"
            /></b-button>
          </div>
          <div>
            <b-button
              @click="createInfocardsOnLocations"
              variant="primary"
              class="w-100"
              :disabled="
                creatingInfocards ||
                infocardsToCreate.length == 0 ||
                selectedLocations.length == 0
              "
              >{{
                creatingInfocards
                  ? T("Web.Generic.Creating", "Creating...")
                  : T(
                      "Web.BackgroundDataPage.WasteTypes.CreateInfocardsOnLocations",
                      "Create infocard on location(s)"
                    )
              }}</b-button
            >
          </div>
        </div>
      </b-container>
    </content-modal>

    <div class="d-flex justify-content-center flex-column">
      <b-row class="topbar mb-1">
        <b-col cols="3">
          <input
            type="text"
            class="form-control"
            :placeholder="T('Web.Generic.Search', 'Search')"
            v-model="search"
          />
        </b-col>
        <b-col>
          <b-button @click="openCreateWasteFractionsModal" variant="primary">
            {{ T("Web.Generic.CreateNew", "Create new") }}
          </b-button>
          <b-button
            v-b-modal.infocards-creation-tool-modal
            variant="flat-secondary"
            class="ml-1"
          >
            {{
              T(
                "Web.BackgroundDataPage.WasteTypes.CreateFractionsAsInfocards",
                "Create fraction(s) as InfoCards"
              )
            }}<feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
        <b-form-checkbox
          switch
          class="mr-1 mb-0"
          v-model="lockWasteFractions"
          @change="updateInfocardSyncSetting"
          >{{
            T(
              "Web.BackgroundDataPage.WasteTypes.SynchronizeInfocardsWithFraction",
              "Synchronize infocards with fractions"
            )
          }}</b-form-checkbox
        >
        <loader class="loader" ref="loaderWasteFraction"></loader>
      </b-row>
      <b-row>
        <b-table
          responsive
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          :fields="tableHeadersFields"
          :items="filterted"
          :key-field="'id'"
          class="background-data-table"
        >
          <template #cell(name)="data">
            <input
              @change="updateWasteFraction(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.name"
            />
          </template>

          <template #cell(pictogramPath)="data">
            <div
              class="d-flex justify-content-between aling-items-center"
              style="width: 300px"
            >
              <img
                v-if="data.item.pictogramPath == null"
                class="fraction-pictogram"
                :src="`${apiBaseUrl()}/images/system/fractions/pictograms/fraction-no-pictogram.jpg`"
              />
              <img
                v-else
                class="fraction-pictogram"
                :src="`${apiBaseUrl()}/images/${data.item.pictogramPath}`"
              />
              <span class="d-flex align-items-center">
                <b-button
                  variant="secondary"
                  size="sm"
                  @click="openFractionPictogramModal(data.item)"
                  >{{ T("Web.Generic.ChoosePictogram", "Choose pictogram") }}
                  <feather-icon icon="ImageIcon" />
                </b-button>
              </span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
              <b-button
                size="sm"
                variant="outline-danger"
                @click="removeWasteFraction(data.item)"
              >
                {{ T("Web.Generic.Archive", "Archive") }}
              </b-button>
            </div>
          </template>

          <template #cell(ewcCodeId)="data">
            <treeselect
              @select="
                (item) => {
                  data.item.ewcCodeId = item.id;
                  updateWasteFraction(data.item);
                }
              "
              style="width: 400px"
              :multiple="false"
              :options="ewcGroupList"
              :defaultOptions="ewcGroupList"
              :disable-branch-nodes="true"
              :show-count="true"
              :async="true"
              :load-options="loadOptions"
              :placeholder="
                T('Web.Generic.EWCCodes.SelectEWCCode', 'Select EWC code')
              "
              search-nested
              :value="
                data.item.ewcCodeId == '00000000-0000-0000-0000-000000000000'
                  ? null
                  : data.item.ewcCodeId
              "
            />
          </template>
          <template #cell(wasteCategory)="data">
            <b-form-select
              @change="updateWasteFraction(data.item)"
              :options="wasteCategories"
              v-model="data.item.wasteCategory"
            />
          </template>
          <template #cell(ghgCodeId)="data">
            <b-form-select
              @change="updateWasteFraction(data.item)"
              :options="ghgCodesOptions"
              v-model="data.item.ghgCodeId"
            />
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CreateWasteFractionsModal from "@/components/modals/infoCards/CreateWasteFractionsModal.vue";
import loader from "@/components/layout/loader.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTable,
  BFormSelect,
  BFormCheckbox,
  BContainer,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import FractionPictogramPickerModal from "@/components/modals/infoCards/FractionPictogramPickerModal.vue";
import { uuid } from "vue-uuid";
import ResourceService from "@/services/base/resource.service";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import LocationsMulitSelect from "@/app/concern-app/user/dashboards/widgets/common/LocationsMulitSelect.vue";
import WasteFractionMultiSelect from "@/app/concern-app/user/dashboards/widgets/common/WasteFractionMultiSelect.vue";

export default {
  components: {
    BContainer,
    BFormInput,
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BFormSelect,
    BFormCheckbox,
    BFormGroup,
    Treeselect,
    CreateWasteFractionsModal,
    loader,
    ConfirmModal,
    FractionPictogramPickerModal,
    ContentModal,
    LocationsMulitSelect,
    WasteFractionMultiSelect,
  },
  async created() {
    await this.fetchEwcs();
    await this.fetchWasteFractions();
    await this.fetchGhgCodes();
    this.lockWasteFractions = (
      await new ResourceService("concern/settings").get("")
    ).data.data.lockInfocardBasedOnWasteFraction;
  },
  data() {
    return {
      search: null,
      ghgCodes: [],
      lockWasteFractions: false,

      selectedLocations: [],
      selectedFractions: [],
      infocardsToCreate: [],
      creatingInfocards: false,
    };
  },
  computed: {
    ...mapGetters({
      wasteFractions: "wasteFractions/list",
      ewcs: "ewcs/list",
      locations: "locations/list",
    }),

    containerTypesSelectOptions() {
      return [
        {
          text: this.T("Web.Generic.Infocards.Types.Container"),
          value: "ContainerInfoCard",
        },
        {
          text: this.T("Web.Generic.Infocards.Types.Account"),
          value: "AccountInfoCard",
        },
      ];
    },

    tableHeadersFields() {
      return [
        {
          key: "pictogramPath",
          label: this.T("Web.Generic.Pictogram", "Pictogram"),
          sortable: false,
        },

        {
          key: "actions",
          label: "",
        },
        {
          key: "name",
          label: this.T("Web.Generic.Name", "Name"),
          sortable: true,
        },
        {
          key: "wasteCategory",
          label: this.T("Web.Generic.Category", "Category"),
          sortable: true,
        },
        {
          key: "ghgCodeId",
          label: this.T("Web.Generic.GHGCode", "GHG Code"),
          sortable: true,
        },
        {
          key: "ewcCodeId",
          label: this.T("Web.Generic.EWCCode", "EWC Code"),
          sortable: false,
        },
      ];
    },
    filterted() {
      if (this.search == null || this.search == "") return this.wasteFractions;

      let filtered = this.wasteFractions.filter((e) => {
        return e.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });

      return filtered;
    },
    ewcGroupList() {
      return this.getEwcGroupeList("");
    },
    ghgCodesOptions() {
      let result = this.ghgCodes.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
      result.sort((a, b) => a.text.localeCompare(b.text));
      result.unshift({
        text: this.T("Web.Generic.None", "None"),
        value: "00000000-0000-0000-0000-000000000000",
      });
      return result;
    },
  },
  methods: {
    ...mapActions({
      fetchWasteFractions: "wasteFractions/fetchList",
      putWasteFractions: "wasteFractions/put",
      deleteWasteFractions: "wasteFractions/delete",
      fetchEwcs: "ewcs/fetchList",
    }),

    async fetchGhgCodes() {
      const service = new ResourceService("background-data/ghg-codes");
      let { data } = await service.get("");
      this.ghgCodes = data.data;
    },

    async openCreateWasteFractionsModal() {
      const result = await this.$refs.createWasteFractionsModal.open("");
      if (result != undefined && result.id.length > 0)
        this.$store.dispatch(`wasteFractions/fetchList`);
    },
    async removeWasteFraction(wasteFraction) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.ArchiveConfirm",
            "Confirm that you are about to archive this item"
          )
        ))
      )
        return;
      this.$refs.loaderWasteFraction.loading();
      await this.deleteWasteFractions({ resourceId: wasteFraction.id });
      this.$store.dispatch(`wasteFractions/fetchList`);
      this.$refs.loaderWasteFraction.loadCompelete();
    },
    async updateWasteFraction(wasteFraction) {
      this.$refs.loaderWasteFraction.loading();
      await this.putWasteFractions({ data: wasteFraction });
      this.$refs.loaderWasteFraction.loadCompelete();
    },

    async openFractionPictogramModal(fraction) {
      var path = await this.$refs.fractionPictogramPickerModal.open();
      if (path != null) {
        fraction.pictogramPath = path;
      }
      await this.putWasteFractions({ data: fraction });
    },
    loadOptions({ action, searchQuery, callback }) {
      callback(null, this.getEwcGroupeList(searchQuery));
    },
    getEwcGroupeList(searchTerm = "") {
      let baseList = this.ewcs;
      if (searchTerm != "") {
        baseList = this.ewcs.filter(
          (e) =>
            e.code.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            e.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            e.eCode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            e.hCode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      }

      const list = baseList
        .filter((e) => e.alias == null)
        .reduce(function (rv, x) {
          (rv[x["categoryName"]] = rv[x["categoryName"]] || {
            label: x["categoryName"],
            children: [],
            id: uuid.v4(),
            isDefaultExpanded: searchTerm != "",
          }).children.push({
            id: x.id,
            code: x.code,
            label: `${x.code} | ${x.name}`,
          });
          return rv;
        }, {});

      let resultList = Object.keys(list).map(function (key) {
        return list[key];
      });
      let filledEwcItems = [];
      baseList
        .filter((e) => e.alias != null)
        .forEach((item) =>
          filledEwcItems.push({
            id: item.id,
            code: item.code,
            label: `${item.code} | ${item.alias}`,
          })
        );
      resultList.unshift({
        id: "filled",
        label: "Filled",
        children: filledEwcItems,
        isDefaultExpanded: true,
      });
      return resultList;
    },
    async updateInfocardSyncSetting() {
      const service = new ResourceService("concern/settings");
      await service.post("infocard-wastefraction-lock", {
        value: this.lockWasteFractions,
      });
    },
    async createInfocardsOnLocations() {
      try {
        this.creatingInfocards = true;
        const self = this;
        const service = new ResourceService("background-data/infocards");

        for (const location of this.selectedLocations) {
          for (const infocard of this.infocardsToCreate) {
            if (infocard.type == "ContainerInfoCard") {
              await service.post("containers", {
                id: uuid.v4(),
                name: infocard.name,
                locationId: location,
                wasteFractionId: infocard.fractionId,
              });
            } else {
              await service.post("accounts", {
                id: uuid.v4(),
                name: infocard.name,
                locationId: location,
              });
            }
          }
        }
        this.$bvModal.hide("infocards-creation-tool-modal");
        this.selectedFractions = [];
        this.selectedLocations = [];
        this.infocardsToCreate = [];
      } finally {
        this.creatingInfocards = false;
      }
    },
    addAccountToInfocardCreateList() {
      this.infocardsToCreate.push({
        id: uuid.v4(),
        name: "",
        type: "AccountInfoCard",
      });
    },
    removeInfocardFromCreateList(id) {
      let indexToRemove = this.infocardsToCreate.indexOf(
        this.infocardsToCreate.find((i) => i.id == id)
      );
      this.infocardsToCreate.splice(indexToRemove, 1);
    },
  },
  watch: {
    selectedFractions(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.infocardsToCreate = this.infocardsToCreate.filter(
        (i) => i.type != "ContainerInfoCard"
      );
      newValue.forEach((item) => {
        this.infocardsToCreate.push({
          id: uuid.v4(),
          name: this.wasteFractions.find((f) => f.id == item).name,
          fractionId: item,
          type: "ContainerInfoCard",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}

.fraction-pictogram {
  height: 50px;
}
</style>