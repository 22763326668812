<template>
  <b-card>
    <confirm-modal ref="confirmModal" />
    <create-reciving-facilities-modal ref="createRecivingFacilitiesModal">
    </create-reciving-facilities-modal>
    <div class="d-flex justify-content-center flex-column">
      <b-row class="topbar mb-1">
        <b-col cols="3">
          <input
            type="text"
            class="form-control"
            :placeholder="T('Web.Generic.Search', 'Search')"
            v-model="search"
          />
        </b-col>
        <b-col>
          <b-button
            @click="openCreateRecivingFacilitiesModal"
            variant="primary"
          >
            {{ T("Web.Generic.CreateNew", "Create new") }}
          </b-button>
        </b-col>
        <loader class="loader" ref="recivingFacilitieLoader"></loader>
      </b-row>
      <b-row>
        <b-table
          class="background-data-table"
          responsive
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          :fields="tableHeadersFields"
          :items="filterted"
        >
          <template #cell(name)="data">
            <input
              @change="updateRecivingFacilitie(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.name"
            />
          </template>
          <template #cell(address)="data">
            <input
              @change="updateRecivingFacilitie(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.address"
            />
          </template>
          <template #cell(zip)="data">
            <input
              @change="updateRecivingFacilitie(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.zip"
            />
          </template>
          <template #cell(city)="data">
            <input
              @change="updateRecivingFacilitie(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.city"
            />
          </template>
          <template #cell()="data">
            <b-button
              variant="outline-danger"
              class="mr-2"
              @click="removeRecivingFacilitie(data.item)"
            >
              {{ T("Web.Generic.Archive", "Archive") }}
            </b-button>
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import CreateRecivingFacilitiesModal from "@/components/modals/infoCards/CreateRecivingFacilitiesModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import loader from "@/components/layout/loader.vue";

import { BCard, BButton, BRow, BCol, BTable } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    CreateRecivingFacilitiesModal,
    loader,
    ConfirmModal,
  },
  async created() {
    this.fetchRecivingFacilities();
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    ...mapGetters({ recivingFacilities: "recivingFacilities/list" }),
    filterted() {
      if (this.search == null || this.search == "")
        return this.recivingFacilities;

      let filtered = this.recivingFacilities.filter((e) => {
        return (
          e.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.address.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.zip.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.city.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });

      return filtered;
    },
    tableHeadersFields() {
      return [
        {
          key: "name",
          label: this.T("Web.Generic.Name", "Name"),
          sortable: true,
        },
        {
          key: "address",
          label: this.T("Web.Generic.Address", "Address"),
          sortable: true,
        },
        {
          key: "zip",
          label: this.T("Web.Generic.Zip", "Zip"),
          sortable: true,
        },
        {
          key: "city",
          label: this.T("Web.Generic.City", "City"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchRecivingFacilities: "recivingFacilities/fetchList",
      putRecivingFacilities: "recivingFacilities/put",
      deleteRecivingFacilities: "recivingFacilities/delete",
    }),

    async openCreateRecivingFacilitiesModal() {
      const result = await this.$refs.createRecivingFacilitiesModal.open("");
      if (result != undefined && result.id.length > 0)
        this.$store.dispatch(`recivingFacilities/fetchList`);
    },
    async removeRecivingFacilitie(recivingFacilitie) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.ArchiveConfirm",
            "Confirm that you are about to archive this item"
          )
        ))
      )
        return;
      this.$refs.recivingFacilitieLoader.loading();
      await this.deleteRecivingFacilities({ resourceId: recivingFacilitie.id });
      this.$store.dispatch(`recivingFacilities/fetchList`);

      this.$refs.recivingFacilitieLoader.loadCompelete();
    },
    async updateRecivingFacilitie(recivingFacilitie) {
      this.$refs.recivingFacilitieLoader.loading();
      await this.putRecivingFacilities({ data: recivingFacilitie });
      this.$refs.recivingFacilitieLoader.loadCompelete();
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
</style>